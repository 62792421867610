import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import joinClasses from '/src/functions/joinClasses'
import './styles.scss'

const Error = loadable(() => import('/src/components/Form/Error'))
const Required = loadable(() =>
    import('/src/components/Form/Required/Required')
)

const FieldSet = ({
    variant,
    align,
    legend,
    groupName,
    description,
    required,
    className,
    children
}) => {
    const classes = [
        'c-fieldset',
        ...(variant ? [`c-fieldset--${variant}`] : []),
        ...(align ? [`c-fieldset--${align}`] : []),
        ...(className ? [className] : [])
    ]

    const concatenatedClasses = joinClasses(classes)

    return (
        <fieldset className={concatenatedClasses}>
            {legend && (
                <legend>
                    {legend}
                    {required && <Required />}
                </legend>
            )}

            {description && <small>{description}</small>}

            <div className="c-fieldset__inner">{children}</div>

            <Error name={groupName} />
        </fieldset>
    )
}

FieldSet.propTypes = {
    /**
     * Fieldset variant
     */
    variant: PropTypes.oneOf(['wrap', 'column']),
    /**
     * Alignment
     */
    align: PropTypes.oneOf(['centered']),
    /**
     * Fieldset legend title
     */
    legend: PropTypes.string,
    /**
     * Checkbox or radio button group name
     */
    groupName: PropTypes.string,
    /**
     * Optional fieldset description
     */
    description: PropTypes.string,
    /**
     * Whether the fields in this fieldset are required
     */
    required: PropTypes.bool,
    /**
     * Optional CSS classes
     */
    className: PropTypes.string,
    /**
     * Inner child elements
     */
    children: PropTypes.node.isRequired
}

FieldSet.defaultProps = {
    groupName: 'consent',
    children: '<p>Fieldset content goes here</p>'
}

export default FieldSet
